@item-gap: 5px;

.align-center {
  display: flex;
  align-items: center;
}

.container {
  .align-center();
  height: 64px;
  justify-content: space-between;
  padding: 0 20px;
  gap: @item-gap;

  .left {
    flex: 1;
    .align-center();
    gap: @item-gap;
  }

  .right {
    flex: none;
    .align-center();
    gap: @item-gap;
  }
}