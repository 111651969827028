@header-height: 64px;

.test {
  background-color: blue;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: @header-height;
  backdrop-filter: blur(10px);
  background-color: var(--ma-palette-background-backdrop);
  border-bottom: 1px solid var(--ma-palette-divider);
  z-index: 10;
}

.container {
  display: flex;
  justify-content: center;
}

.main {
  margin-top: @header-height;
  width: 100%;
  max-width: 768px;
}