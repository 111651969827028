.intro-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.intro-figure {
  .img-wrapper {
    width: 100%;

    img {
      width: 100%
    }
  }
}

.footer-fix {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}